import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages,  Flex, FlexContent, TitleServices, ListServices, BigTitleSmallPages, BoxImages} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const chorobykrolikowigryzoni = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
    title="Choroby królików i gryzoni"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący szczepienia, zdjęcia RTG, leczenie chorób królików, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="leczenie królików kalisz, króliki kalisz, gryzonie kalisz"
    />
    
    <Navbar />
    <BigTitleSmallPages style={{justifyContent: 'center'}}

         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Choroby gryzoni</BigTitleSmallPages>  
         
         <FlexContent>
          <UslugiPages />
 <Flex>

 
    <BoxTextPages>

<TitleServices>Leczenie królików i gryzoni w zakresie: </TitleServices>
<ListServices> Szczepienia</ListServices>
<ListServices> Zdjęcia RTG</ListServices>
<ListServices> Porady</ListServices>
<ListServices> Pielęgnacja</ListServices>
<ListServices> Żywienie</ListServices>
<ListServices> Leczenie chorób zakaźnych</ListServices>


    </BoxTextPages>
<BoxImages>
 <StaticImage
    src="../images/krolik.jpeg"
    alt="choroby królików i gryzoni"
    width={1000}
    layout="constrained"
    placeholder="blurred"
    className='staticimagepages'
    />
</BoxImages>
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}

export default chorobykrolikowigryzoni